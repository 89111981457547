import React, { useState, useEffect, useContext } from 'react';
import { StoreContext } from '../../store/store';
import { text, countryMarket } from '../../utils/global';
import Button from '../Button/Button';
import axios from 'axios';
import VFLogger from '../../utils/Logger';
import { getSmapiPageName } from '../../constants/smapiMapper';

import { RESPONSE_SUCCESS, RESPONSE_ERROR } from '../../constants/actionTypes';

const EmailVerification = (props) => {
  const { state, dispatch } = useContext(StoreContext);
  console.log('--- data ---');
  console.log(state.data);

  const finaliseRegistration = (e) => {
    VFLogger.info(`${getSmapiPageName(state.data.context)}: clicks on continue button`);
    e.preventDefault();
    const finaliseRegistrationLink = state.data.links['urn:vodafoneid:finaliseRegistration'];
    const options = {
      headers: {
        Accept: finaliseRegistrationLink.type,
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

    console.log('--- request ---');
    console.log(finaliseRegistrationLink.href);

    axios
      .get(finaliseRegistrationLink.href, { withCredentials: true, options })
      .then((response) => {
        console.log('--- response ---');
        console.log(response);
        VFLogger.info(`${getSmapiPageName(state.data.context)}: process: ${state.data.context}`);

        dispatch({
          type: RESPONSE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        VFLogger.info(`${getSmapiPageName(state.data.context)}: failed: ${error}`);
        console.log('--- error ---');
      });
  };

  return (
    <>
      <div className="text-wrapper">
        <>
          {state.data && state.data.identifier && (
            <>
              <div>
                <>{state.data.identifier.value}</>
              </div>
              <br />
            </>
          )}
        </>

        {text('introduction.to.es_email_verification.page')}
        {text('secondary.introduction.to.es_email_verification.page')}
      </div>

      <div className="section-form">
        <div className="validate-form">
          <div className="box">
            <Button
              type="primary"
              label={text('submit.button.label')}
              id="submitkButton"
              handleClick={(e) => finaliseRegistration(e)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerification;
