import React from 'react';
import Error from '../Error/Error';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error and error information to an error reporting service
    console.error('ErrorBoundary caught an error', error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return <Error title="Something went wrong" text="Please, close your browser tab and try again" />;
    }

    // If no error, render children normally
    return this.props.children;
  }
}

export default ErrorBoundary;
