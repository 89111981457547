export default function buildAuthUrl() {
  let authorize = process.env.REACT_APP_AUTHORIZE_ENDPOINT;

  // Hash Implementation -- Call the correct web service according to hash value in url
  var queryString = window.location.search.substr(1);
  var url;

  var hash = window.location.hash.substring(1, window.location.hash.length + 1); // strip off the #
  var key = '';
  if (hash.indexOf('::') != -1) {
    key = hash.substring(hash.lastIndexOf('::') + '::'.length);
    hash = hash.substring(0, hash.lastIndexOf('::'));
  }

  let token = '';
  switch (hash) {
    case 'email':
      url = '/web-apis/validate/email/' + key;
      break;
    case 'password':
      url = '/web-apis/password-reset/validate/email/' + key;
      break;
    case 'password-federated':
      url = '/web-apis/v2/password-reset/validate/email/' + key;
      break;
    case 'account':
      url = '/web-apis/selfcare?' + queryString;
      break;
    case 'kyc_confirm':
      url = '/web-apis/selfcare/kyc?' + queryString;
      break;
    case 'finaliseRegistration':
      token = queryString.substring(queryString.indexOf('=') + 1, queryString.length);
      url = '/web-apis/externalregistration/finalise/' + token;
      break;
    case 'finaliseResetPassword':
      token = queryString.substring(queryString.indexOf('=') + 1, queryString.length);
      url = '/web-apis/externalreset/finalise/' + token;
      break;
    case '':
      //Only for development - local
      if (process.env.NODE_ENV !== 'development') {
        url = authorize + '?' + queryString;
      } else {
        url = authorize;
      }
      break;
    default:
      if (hash == '/register') {
        url = '/web-apis/authorize?' + queryString;
        break;
      }
      if (hash.indexOf('/') == 0) {
        url = '/web-apis' + hash + '?' + queryString;
      } else {
        url = '/web-apis/' + hash + '?' + queryString;
      }
  }

  // let url = authorize + '?' + window.location.search.substr(1);
  if (process.env.NODE_ENV !== 'development') {
    url = process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_IAM_ENDPOINT + url;

    url = window.location.href.substr(0, 11) + url.substr(8);
  }

  // Console Log
  console.log('URL for first authorize call : ' + url);

  //VFLogger.log('URL :: ' + url);

  let requestUrl = {
    method: 'get',
    href: url,
  };

  return requestUrl;
  //ServiceRequest(requestUrl, null, RequestCallBack);
}
