import { extractLocale, extractLanguage } from './global';
import i18n from '../i18n';
import { SETTING_TERMS, SETTING_PRIVACY, SETTING_COOKIE } from '../constants/actionTypes';

export const setConfigs = (response, dispatch) => {
  return new Promise(function (resolve, reject) {
    try {
      loadFooterContent(response);
      loadConfig(response, dispatch);
      resolve(response);
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

export const loadFooterContent = (response) => {
  let locale = response.data.market.locale.substring(3, 5).toLowerCase();

  const termsAndConditions = document.createElement('script');
  termsAndConditions.src = process.env.REACT_APP_CDN_TERMS_URL + locale + '.js';
  termsAndConditions.async = true;
  termsAndConditions.onload = () => scriptLoaded();

  document.body.appendChild(termsAndConditions);

  const privacyPolicy = document.createElement('script');
  privacyPolicy.src = process.env.REACT_APP_CDN_POLICY_URL + locale + '.js';
  privacyPolicy.async = true;
  privacyPolicy.onload = () => scriptLoaded();

  document.body.appendChild(privacyPolicy);
};

export const loadConfig = (response, dispatch) => {
  let countryCode = '',
    content = {};

  //Fetch Country Code
  if (process.env.NODE_ENV !== 'development') {
    countryCode = String(window.location.href.substr(8, 2)).toUpperCase();
  } else {
    countryCode = process.env.REACT_APP_COUNTRY;
  }

  if (countryCode === 'NV') {
    content = JSON.parse(process.env.REACT_APP_NV_MARKET);
  } else {
    JSON.parse(process.env.REACT_APP_MARKET).market.map(function (value) {
      if (value.countryCode == countryCode) {
        content['market'] = value;
      }
    });
  }

  const smapiEnabled = response.data.market.smapiEnable ? response.data.market.smapiEnable : false;
  Object.assign(content, { smapiEnabled: smapiEnabled });

  const cookieManagement = {
    cookieBannerEnabled: response.data.market.cookiesManagement
      ? response.data.market.cookiesManagement.cookieBannerEnabled
      : false,
    manageCookieLinkEnabled: response.data.market.cookiesManagement
      ? response.data.market.cookiesManagement.manageCookieLinkEnabled
      : false,
    functionalCookiesEnabled: response.data.market.cookiesManagement
      ? response.data.market.cookiesManagement.functionalCookiesEnabled
      : false,
  };

  Object.assign(content, { cookieManagement: cookieManagement });

  const analyticsConfig = {
    tealium_account: response.data.analyticsTealiumAccount,
    tealium_profile: response.data.analyticsTealiumProfile,
    tealium_datasource: response.data.analyticsTealiumDatasource,
    tealium_enabled:
      response.data.market.analyticsTealiumConfigs &&
      response.data.market.analyticsTealiumConfigs.analyticsEnabled === 'true'
        ? true
        : false,
    tealium_market_mid_url: response.data.market.analyticsTealiumConfigs
      ? response.data.market.analyticsTealiumConfigs.analyticsMarketMidUrl
      : '',
    tealium_vodafone_group_mid_url: response.data.market.analyticsTealiumConfigs
      ? response.data.market.analyticsTealiumConfigs.analyticsVodafoneGroupMidUrl
      : '',
    env: response.data.analyticsEnv,
    page_country: response.data.market.analyticsTealiumConfigs
      ? response.data.market.analyticsTealiumConfigs.analyticsPageCountry
      : '',
    endpoint: response.data.analyticsApiEndpoint,
  };

  Object.assign(content, { analytics: analyticsConfig });

  window.loc = response.data.text;
  //Assigning market config (stored in environment file) to window.config variable
  window.config = content;
  if (window.config.market !== null) {
    //let country = window.config.market.countryCode;
    let terms = false;
    let privacy = false;

    // if(process.env.NODE_ENV === 'development'){
    //   country = process.env.REACT_APP_COUNTRY
    // }

    if (countryCode === 'NV') {
      countryCode = extractLocale();
    } else {
      extractLanguage(window.config.market.locale);
    }

    if (
      window.config.market.termsAndConditionEnable !== undefined &&
      window.config.market.termsAndConditionEnable === true
    ) {
      terms = true;
    }

    if (window.config.market.privacyPolicyEnable !== undefined && window.config.market.privacyPolicyEnable === true) {
      privacy = true;
    }

    if (window.config.market.countryCode === 'NV') {
      window.config.market.countries.map(function (key) {
        if (key.countryCode === countryCode) {
          if (key.termsAndConditionEnable === true) {
            terms = true;
          }
          if (key.privacyPolicyEnable === true) {
            privacy = true;
          }
        }
      });
    }

    countryCode = countryCode.toLowerCase();
    window.countryCode = countryCode;
    if (terms === true) {
      i18n.changeLanguage(countryCode);
      dispatch({
        type: SETTING_TERMS,
        terms: terms,
      });
    }
    if (privacy === true) {
      i18n.changeLanguage(countryCode);
      dispatch({
        type: SETTING_PRIVACY,
        privacy: privacy,
      });
    }
    //Cookie Banner Dispaly
    if (process.env.REACT_APP_COOKIE_BANNER_ENABLED == 'true') {
      i18n.changeLanguage(countryCode);
      dispatch({
        type: SETTING_COOKIE,
        cookie: true,
      });
    }
  }
};

const scriptLoaded = () => {
  console.log('scriptLoaded');
  console.log('window.termsAndCondition', window.termsAndCondition);
};
