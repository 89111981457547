import React, { useState, useContext, useEffect } from 'react';
//import "./Button.scss";
import { StoreContext } from '../../store/store';
import Button from '../Button/Button';
import { text } from '../../utils/global';
import { ServiceRequest, handleLink } from '../../utils/Services';
import Timer from '../Timer/Timer';
import { SPLASH_LOAD } from '../../constants/actionTypes';
import analyticsTealium from '../../utils/Analytics/analytics';
import { getPageName } from '../../constants/tealiumMapper';

const EndFlow = (props) => {
  let { state, dispatch } = useContext(StoreContext);
  let buttons = [];
  const [redirectButtonType, setRedirectButtonType] = useState('primary');
  const [redirectMsg, setRedirectMsg] = useState('');
  let endTimer = false;
  const { trackPageEvent, trackPageView } = analyticsTealium();
  useEffect(() => {
    if (window.config !== undefined) {
      setRedirectMsg(' ' + text('timer.units.in.seconds'));
    }
  });

  if (window.config !== undefined && (state.data.links !== null || state.data.links !== undefined)) {
    //Redirect button for END_FLOW context
    if (state.data.links['urn:vodafoneid:redirect']) {
      //startTimer();
      buttons.push(
        <div className="btn-wrapper" key="btn2">
          <Button
            type={redirectButtonType}
            label={text('redirect.me.now.button.label')}
            id="redirectButton"
            handleClick={(event) => {
              trackPageEvent(`${getPageName(state.data.context)}`, {
                eventCategory: 'button',
                eventLabel: text('redirect.me.now.button.label'),
              }),
                redirectLinkHandler(event);
            }}
          />
        </div>
      );
      if (state.page_look !== 'mva') {
        endTimer = (
          <Timer
            display={true}
            text={redirectMsg}
            countdown={process.env.REACT_APP_AUTO_REDIRECT_IN_SEC}
            units={redirectMsg}
            action={() => {
              redirectService();
              trackPageView(`${getPageName(state.data.context)}:countdown`, { flowName: 'login', flowType: 'login' });
            }}
          ></Timer>
        );
      }
    }

    //Return to log in service
    if (state.data.links['urn:vodafoneid:back']) {
      //startTimer();
      buttons.push(
        <div className="btn-wrapper no-margin" key="btn3">
          <Button
            type={redirectButtonType}
            label={text('return.to.log.in.button.label')}
            id="returnButton"
            handleClick={() => {
              trackPageEvent(`${getPageName(state.data.context)}:returntologin`, {
                eventCategory: 'button',
                eventLabel: text('return.to.log.in.button.label'),
              }),
                returnLinkHandler();
            }}
          />
        </div>
      );
    }

    //Return to Service page in REgistration success
    if (state.data.links['urn:vodafoneid:follow']) {
      //startTimer();
      buttons.push(
        <div className="btn-wrapper no-margin" key="btn3">
          <Button
            type={redirectButtonType}
            label={text('return.to.account.button.label')}
            id="returnButton"
            handleClick={() => {
              trackPageEvent(`${getPageName(state.data.context)}`, {
                eventCategory: 'button',
                eventLabel: text('return.to.account.button.label'),
              }),
                returnServiceHandler();
            }}
          />
        </div>
      );
    }

    // Try again
    if (state.data.links['urn:vodafoneid:tryagain']) {
      //startTimer();
      buttons.push(
        <div className="btn-wrapper no-margin" key="btn3">
          <Button
            type={redirectButtonType}
            label={text('try.again.button.label')}
            id="tryAgainnButton"
            handleClick={() => {
              trackPageEvent(`${getPageName(state.data.context)}:retry`, {
                eventCategory: 'button',
                eventLabel: text('redirect.me.now.button.label'),
              }),
                tryAgainHandler();
            }}
          />
        </div>
      );
    }
  }

  function redirectLinkHandler() {
    setRedirectButtonType('primary spinner disabled');
    redirectService();
    handleLink(
      state.data.links['urn:vodafoneid:redirect'],
      props.RequestCallBack,
      '',
      null,
      'Click on - Redirect Me',
      'Clicked on Redirect Me'
    );
  }

  function redirectService() {
    dispatch({
      type: SPLASH_LOAD,
      splash: true,
    });
    handleLink(
      state.data.links['urn:vodafoneid:redirect'],
      props.RequestCallBack,
      '',
      null,
      'Redirect to service',
      'Redirect to service'
    );
  }

  function tryAgainHandler() {
    dispatch({
      type: SPLASH_LOAD,
      splash: true,
    });

    // Simple redirection
    if (state.data && state.data.links['urn:vodafoneid:tryagain']) {
      window.location.href = state.data.links['urn:vodafoneid:tryagain'].href;
    }
  }

  function returnServiceHandler() {
    setRedirectButtonType('primary spinner disabled');
    const logInfo = {
      page: state.data.context,
    };
    ServiceRequest(state.data.links['urn:vodafoneid:follow'], null, props.RequestCallBack, 'self', logInfo);
  }

  function returnLinkHandler() {
    setRedirectButtonType('primary spinner disabled');
    const logInfo = {
      page: state.data.context,
    };
    ServiceRequest(state.data.links['urn:vodafoneid:back'], null, props.RequestCallBack, 'self', logInfo);
  }

  return (
    <div className="section-form">
      {buttons}
      {endTimer}
    </div>
  );
};

export default EndFlow;
