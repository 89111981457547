import { useState, useEffect, useContext } from 'react';
import { StoreContext } from '../../store/store';
import { t, validateIdentifier } from '../../utils/global';
import { RESPONSE_ERROR_CLEAR } from '../../constants/actionTypes';

const useForm = (callback) => {
  const [values, setValue] = useState({ identifier: '' });
  const [errors, setErrors] = useState({});
  const [buttonType, setButtonType] = useState('primary disabled');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [identifierTitle, setIdentifierTitle] = useState('hidden');
  const [identifierPadding, setIdentifierPadding] = useState('paddingTop20');
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);
  const [identifierType, setIdentifierType] = useState('');
  let { state, dispatch } = useContext(StoreContext);

  useEffect(() => {
    setIdentifierType(state.data.identifier.type);

    // if(state.data.identifier && state.data.identifier.value){
    //     setButtonType('primary');
    // }
  });

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('--- callback ---');

      callback();
    }
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setIsSubmitting(true);
    validateForm();
    event.target.blur();
  };

  const handleChange = (event) => {
    //Set Validation
    let replaceExp = '';

    if (state.errorObj && state.errorObj.msg) {
      dispatch({
        type: RESPONSE_ERROR_CLEAR,
        payload: {},
      });
    }

    setValue((values) => ({ ...values, [event.target.name]: event.target.value.trim() }));
    if (event.target.value.trim() !== '' && event.target.value.length > 0) {
      setIsCheckboxDisabled(false);
      setButtonType('primary');
      setIdentifierTitle('');
      setIdentifierPadding('');
      setIsSubmitting(false);
      //setErrors({});
    } else {
      setButtonType('primary disabled');
      setIdentifierTitle('hidden');
      setIdentifierPadding('paddingTop20');
    }
  };

  const handleBlur = (event) => {
    setErrors({});
    validateForm();
  };

  function validateForm() {
    let errMsg = validateIdentifier(identifierType, values.identifier, state.data.context);
    if (errMsg !== undefined && errMsg !== false) {
      setErrors((errors) => ({ ...errors, msg: errMsg, className: 'inline_error' }));
      setButtonType('primary disabled');
    } else {
      setErrors({});
    }
  }

  const resetValues = () => {
    setValue((values) => ({ ...values, identifier: '' }));
    setButtonType('primary disabled');
    setIdentifierTitle('hidden');
    setIdentifierPadding('paddingTop20');
    setIsSubmitting(false);
    setErrors({});
  };

  const resetError = () => {
    setValue((values) => ({ ...values, identifier: '' }));
    setIdentifierTitle('hidden');
    setIdentifierPadding('paddingTop20');
    setErrors({});
  };

  return {
    errors,
    values,
    identifierTitle,
    setIdentifierTitle,
    identifierPadding,
    setIdentifierPadding,
    buttonType,
    setButtonType,
    isCheckboxDisabled,
    setIsCheckboxDisabled,
    resetValues,
    resetError,
    handleSubmit,
    handleChange,
    handleBlur,
  };
};

export default useForm;
