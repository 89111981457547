import React, { useState, useRef, useEffect, useContext } from 'react';
import IdleTimer from 'react-idle-timer';
import { Base64 } from 'js-base64';
import Cookies from 'js-cookie';
import { StoreContext } from '../../store/store';
import { SPLASH_LOAD } from '../../constants/actionTypes';
import VFLogger from '../../utils/Logger';
import { getPageName } from '../../constants/tealiumMapper';
import analyticsTealium from '../../utils/Analytics/analytics';

function SessionKiller(props) {
  const { trackPageView } = analyticsTealium();
  let timerid = useState(-1);
  let authCookieData = {};
  let whatTimeRem = 0;
  let getErrorUri = props.sessionURL;
  let idleTimer = null;
  let idpCurrentTime = null,
    diffCurentTime = null;

  const { dispatch } = useContext(StoreContext);
  if (Cookies.get('authState')) {
    console.log('Authorize:::Call::Auth_cookie_details::', JSON.parse(Base64.atob(Cookies.get('authState'))));
    authCookieData = JSON.parse(Base64.atob(Cookies.get('authState')));
    idpCurrentTime = new Date().getTime();
    diffCurentTime = idpCurrentTime - authCookieData.currentTime;
    whatTimeRem = authCookieData.epochExpTime - (authCookieData.currentTime + diffCurentTime);
    getErrorUri = authCookieData['error-url'];
  }
  let sessionTimeInMs = whatTimeRem;
  let timeout = sessionTimeInMs;
  //Setting to mins to display on console
  whatTimeRem = whatTimeRem / 1000;

  useEffect(() => {
    clearTimeout({ timerid });
    getSessionTimeout();
    elapsedTimeCounter();
  }, []);

  function getSessionTimeout() {
    console.log('remaining session tim in seconds', sessionTimeInMs);
    if ({ timerid }) {
      clearTimeout(timerid);
    }
    setTimeout(() => {
      dispatch({
        type: SPLASH_LOAD,
        splash: true,
      });
      console.log('Redirect to error URI after transaction timeout');
      trackPageView(getPageName('FOLLOW'), { flowName: 'login', flowType: 'login' });
      Cookies.remove('authState');
      window.location.href = getErrorUri;
    }, sessionTimeInMs);
  }
  function elapsedTimeCounter() {
    setInterval(function () {
      whatTimeRem = Math.floor(whatTimeRem - 1);
      if (whatTimeRem > 60) {
        // Show minutes and seconds
        // console.log( "Tick - " + (Math.floor(whatTimeRem / 60)) + ":" + whatTimeRem % 60 );
      } else if (whatTimeRem > 0) {
        // Show seconds
        // console.log( "Tick - " + whatTimeRem );
      }
    }, 1000);
  }

  function _onAction(e) {
    //console.log('User seen active and action on screen::', e);
  }

  function _onActive(e) {
    Cookies.remove('authState');
  }

  function _onIdle(e) {
    Cookies.remove('authState');
    window.location.href = getErrorUri;
  }

  const DEFAULT_EVENTS = [
    'mousemove',
    //'keydown',
    'wheel',
    'DOMMouseScroll',
    'mouseWheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
    'visibilitychange',
  ];

  return (
    <>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        element={document}
        onActive={_onActive}
        onIdle={_onIdle}
        events={DEFAULT_EVENTS}
        debounce={250}
        onAction={_onAction}
        timeout={timeout}
      />
    </>
  );
}
export default SessionKiller;
