import React from 'react';
import { AuthorizeMain } from './components/Authorize/AuthorizeMain';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
const App = () => {
  return (
    <ErrorBoundary>
      <React.Fragment>
        <Router basename={process.env.PUBLIC_URL}>
          <Switch>
            <Route path="/" component={AuthorizeMain} />
          </Switch>
        </Router>
      </React.Fragment>
    </ErrorBoundary>
  );
};

export default App;
