const PAGE_NAME_MAP = {
  VALIDATE: 'VALIDATE',
  VALIDATE_GB_USERNAME: 'VALIDATE',
  VALIDATE_ES_USERNAME: 'VALIDATE',
  VALIDATE_FORGOT_USERNAME: 'VALIDATE_FORGOT_USERNAME',
  AUTHENTICATE: 'AUTHENTICATE',
  END_FLOW_LOADING: 'END_FLOW_LOADING',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  CONFIRM_PIN: 'CONFIRM_PIN',
  REGISTRATION: 'REGISTRATION',
  ES_EMAIL_VERIFICATION: 'ES_EMAIL_VERIFICATION',
  CONFIRM_RESET_PASSWORD: 'CONFIRM_RESET_PASSWORD',
  CONFIRM_PIN_PASSWORD: 'CONFIRM_PIN_PASSWORD',
  WELCOME_BACK: 'WELCOME_BACK',
  FOLLOW: 'FOLLOW',
  END_FLOW: 'END_FLOW',
  ACCOUNT_SELECTION: 'ACCOUNT_SELECTION',
};

/**
 * Returns page name used in the analytics reports according to the internal page ids
 * @param {string} pageId - ID of the page (context name)
 * @returns {string} page name from the pre-configured map
 */
function getSmapiPageName(pageId) {
  if (!PAGE_NAME_MAP.hasOwnProperty(pageId)) {
    console.error(`Smapi page name for context "${pageId}" is not found`);
    return pageId;
    //throw new Error(`Analytics: page name for ID "${pageId}" is not found`);
  }

  return PAGE_NAME_MAP[pageId];
}

export { getSmapiPageName };
