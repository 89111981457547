import React, { useState, useEffect, useContext, useRef } from 'react';
import { StoreContext } from '../../store/store';
import Button from '../Button/Button';
import useForm from './useForm';
import { text, countryMarket } from '../../utils/global';
import { ServiceRequest, handleLink } from '../../utils/Services';
import Identifier from '../Identifier/Identifier';
import Link from '../Link/Link';
import Checkbox from '../Checkbox/Checkbox';
import VFLogger from '../../utils/Logger';
import analyticsTealium from '../../utils/Analytics/analytics';
import ReCAPTCHA from 'react-google-recaptcha';
import { logFocusedField } from '../../utils/utils';
import { getSmapiPageName } from '../../constants/smapiMapper';

const Validate = (props) => {
  const recaptchaRef = useRef();
  const { trackPageEvent } = analyticsTealium();
  let { state } = useContext(StoreContext);
  let buttons = [];
  let identifierTag = false,
    identifierDisplay = false;
  let identifierLabel = state.data.identifier.type === 'tel' ? 'msisdn' : state.data.identifier.type;
  let market = undefined,
    captcha = '';
  let forgotUsername,
    register,
    rememberMyUsernameCheckbox,
    subTitle,
    backendMsg = false;
  const [isRemember, setRemember] = useState(
    state.data.fields !== undefined && state.data.fields.rememberMyUsernameCheckbox !== undefined
      ? state.data.fields.rememberMyUsernameCheckbox[0].value === true
        ? 'checked'
        : ''
      : false
  );
  const [captchaToken, setCaptchaToken] = useState(null);

  const [isInfoShown, setInfoShown] = useState(false);
  let logInfo;
  const {
    errors,
    values,
    identifierTitle,
    setIdentifierTitle,
    identifierPadding,
    setIdentifierPadding,
    buttonType,
    setButtonType,
    isCheckboxDisabled,
    setIsCheckboxDisabled,
    resetValues,
    resetError,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useForm((e) => onSubmitWithReCAPTCHA(e));

  //DOM load event
  useEffect(() => {
    resetValues();

    setButtonType('primary disabled');
    if (state.data.identifier && state.data.identifier.value) {
      setButtonType('primary');
    }

    // reset reCAPTCHA
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  }, []);

  useEffect(() => {
    // On Error Clear the screen
    if (
      state.errorObj &&
      state.errorObj.msg &&
      (state.errorObj.type === 'error' || state.errorObj.type === 'warning')
    ) {
      setRemember(false);
      resetValues();
    }
  }, [state.errorObj, state.data.message]);

  const onSubmitWithReCAPTCHA = async () => {
    console.log('--- onSubmitWithReCAPTCHA ---');

    if (recaptchaRef.current) {
      // Submit with reCAPTCHA
      try {
        console.log('reCAPTCHA: get token');

        const token = await recaptchaRef.current.executeAsync();

        console.log('token: ' + token);

        // reset reCAPTCHA
        recaptchaRef.current.reset();

        if (!token) {
          console.log('reCAPTCHA: get token failed');
        }

        submitForm(token);
      } catch (error) {
        console.log('ERROR: ' + error);
      }
    } else {
      // Normal submit
      submitForm();
    }
  };

  //Submit Validate Form
  function submitForm(token) {
    VFLogger.info(`${getSmapiPageName(state.data.context)}: form submit`);
    console.log('--- submitForm ---');

    setButtonType('primary spinner disabled');

    let identifier_value = values.identifier;
    if (state.data.identifier.type === 'tel') {
      identifier_value = '+' + market.msisdnPrefix + identifier_value;
    } else if (state.data.identifier.value !== undefined) {
      identifier_value = state.data.identifier.value;
    }

    let payload = {
      identifier: identifier_value,
      identifierType: state.data.identifier.type,
    };

    if (rememberMyUsernameCheckbox !== false && isRemember !== '') {
      payload['remember_my_username'] = isRemember;
    }

    // Add reCAPTCHA token
    if (token !== undefined) {
      payload['recaptcha_token'] = token;
    }

    payload = JSON.stringify(payload);
    logInfo = {
      page: state.data.context,
    };

    ServiceRequest(state.data.links['urn:vodafoneid:submitIdentifier'], payload, props.RequestCallBack, null, logInfo);
  }

  //Back button
  function handleBack(event) {
    resetError();
    logInfo = {
      page: state.data.context,
    };
    VFLogger.info(`${getSmapiPageName(state.data.context)}: clicks on back button`);
    ServiceRequest(state.data.links['urn:vodafoneid:back'], null, props.RequestCallBack, 'self', logInfo);
  }

  //On Key Press Handler for Key Press on MSISDN input field
  function handleKeyPress(event) {
    var charCode = event.which ? event.which : event.keyCode;

    if (charCode == 13) {
      handleSubmit(event);
    } else if (state.data.identifier.type === 'tel') {
      var charCode = event.which ? event.which : event.keyCode;
      if ((charCode >= 48 && charCode <= 57) || charCode == 8 || charCode == 0) {
        return true;
      } else {
        event.preventDefault();
      }
    } else {
      return true;
    }
  }

  //Link Handler
  function handleLinks(link, callBack, target, desc) {
    const opco = ['ES'];
    if (market.countryCode !== undefined && opco.indexOf(market.countryCode) >= 0) {
      target = 'self';
    }
    resetError();
    logInfo = {
      page: state.data.context,
    };
    handleLink(link, callBack, target, logInfo);
  }

  //Remember My Username Checkbox Handler
  function handleCheckbox(e) {
    if (e.target.checked) {
      VFLogger.info(`${getSmapiPageName(state.data.context)}: remember me checked`);
    } else {
      VFLogger.info(`${getSmapiPageName(state.data.context)}: remember me unchecked`);
    }
    setRemember(e.target.checked);
  }

  //Get Identifier
  if (state.data.identifier !== null || state.data.identifier !== undefined) {
    if (window.config !== undefined) {
      let country = state.data.nvMarket !== undefined ? state.data.nvMarket.toUpperCase() : undefined;
      market = countryMarket(state.data.nv, country);
    }
    if (state.data.identifier.value !== undefined) {
      identifierDisplay = (
        <div className="user-name">
          <div className="section-form-label">{text('label.for.identifier.' + identifierLabel)}</div>
          <div className="section-form-label notyoulink">
            {state.data.identifier.value} &nbsp;
            <a
              id="notyoulink"
              onClick={() => {
                trackPageEvent(state.data.context, {
                  eventCategory: 'link',
                  eventLabel: text('not.received.pin.edit.username.label'),
                }),
                  handleLink(state.data.links['urn:vodafoneid:changeidentifier'], props.RequestCallBack);
              }}
            >
              {text('not.received.pin.edit.username.label')}
            </a>
          </div>
        </div>
      );
    } else {
      identifierTag = (
        <>
          {state.data.showCredentialsHelpText &&
            state.data.identifier &&
            state.data.identifier.type &&
            state.data.identifier.type === 'username' && (
              <>
                <div className="info-button-icon" onClick={() => setInfoShown(true)}></div>

                {isInfoShown && (
                  <div className="toast-message-overlay" onClick={() => setInfoShown(false)}>
                    <div
                      className="toast-message"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="toast-message-header">
                        <button
                          className="button icon close"
                          id="close"
                          type="button"
                          onClick={() => setInfoShown(false)}
                        ></button>
                      </div>
                      <div className="toast-message-title">{text('credentials.help.text.title')}</div>
                      <div className="toast-message-text">{text('credentials.help.text')}</div>
                    </div>
                  </div>
                )}
              </>
            )}

          <Identifier
            identifierTitle={identifierTitle}
            className={errors.className}
            identifierPadding={identifierPadding}
            market={market}
            handleChange={handleChange}
            handleKey={handleKeyPress}
            handleBlur={handleBlur}
            value={values.identifier}
            errMsg={errors.msg}
            handleFocus={(e) => logFocusedField(getSmapiPageName(state.data.context), state.data.identifier.type)}
          ></Identifier>
        </>
      );
    }
  }

  //Buttons / links
  if (state.data.links !== null || state.data.links !== undefined) {
    //Submit button (Continue)
    if (state.data.links['urn:vodafoneid:submitIdentifier']) {
      buttons.push(
        <div className="btn-wrapper" key="btn1">
          <Button
            type={buttonType}
            label={text('submit.button.label')}
            id="continueButton"
            handleClick={(event) => {
              trackPageEvent(state.data.context, { eventCategory: 'button', eventLabel: text('submit.button.label') }),
                handleSubmit(event);
            }}
          />
        </div>
      );
    }

    //Back Button
    if (state.data.links['urn:vodafoneid:back']) {
      buttons.push(
        <div className="btn-wrapper" key="btn2">
          <Button
            type="secondary"
            label={text('back.button.label')}
            id="backButton"
            handleClick={() => {
              trackPageEvent(state.data.context, { eventCategory: 'button', eventLabel: text('back.button.label') }),
                handleBack(event);
            }}
          />
        </div>
      );
    }

    // Forgot Username link
    if (state.data.links['urn:vodafoneid:forgotUsername']) {
      forgotUsername = (
        <Link
          id="forgotUsername"
          handleLink={() => {
            trackPageEvent(state.data.context, {
              eventCategory: 'link',
              eventLabel: text('forgot.' + state.data.identifier.type + '.link.text'),
            }),
              handleLinks(
                state.data.links['urn:vodafoneid:forgotUsername'],
                props.RequestCallBack,
                null,
                'Click on - Forgot Username'
              ),
              VFLogger.info(`${getSmapiPageName(state.data.context)}: clicks on forget ${state.data.identifier.type}`);
          }}
          label={text('forgot.' + state.data.identifier.type + '.link.text')}
        ></Link>
      );
    }

    //Register Link
    if (state.data.links['urn:vodafoneid:register']) {
      if (
        state.data.links['urn:vodafoneid:register'].control !== undefined &&
        state.data.links['urn:vodafoneid:register'].control === 'button'
      ) {
        register = (
          <Button
            type="secondary"
            label={text('register.link.text')}
            id="register"
            handleClick={() => {
              trackPageEvent(state.data.context, { eventCategory: 'button', eventLabel: text('register.link.text') }),
                handleLinks(
                  state.data.links['urn:vodafoneid:register'],
                  props.RequestCallBack,
                  null,
                  'Click on - Register Button'
                );
            }}
          />
        );
      } else {
        register = (
          <Link
            id="register"
            handleLink={() => {
              trackPageEvent(state.data.context, { eventCategory: 'link', eventLabel: text('register.link.text') }),
                handleLinks(
                  state.data.links['urn:vodafoneid:register'],
                  props.RequestCallBack,
                  null,
                  'Click on - Register Link'
                ),
                VFLogger.info(`${getSmapiPageName(state.data.context)}: clicks on register link`);
            }}
            className={'text-center'}
            intro={text('register.link.intro.text')}
            label={text('register.link.text')}
          ></Link>
        );
      }
    }

    //Remember my username checkbox
    if (state.data.fields !== undefined && state.data.fields.rememberMyUsernameCheckbox !== undefined) {
      rememberMyUsernameCheckbox = (
        <Checkbox
          id="remember_username"
          checked={isRemember}
          handleCheckbox={(e) => handleCheckbox(e)}
          disable={isCheckboxDisabled}
          label={text(state.data.fields.rememberMyUsernameCheckbox[0].name)}
        ></Checkbox>
      );
    }

    //Sub Title
    subTitle =
      text('secondary.page.intro.for.' + state.data.context + '.page') === undefined
        ? text('introduction.to.' + state.data.context + '.page')
        : text('secondary.page.intro.for.' + state.data.context + '.page');
  }

  return (
    <>
      {/* {state.errorObj && state.errorObj.msg && (<FormErrorBox messageClass="form-error" error={text(state.errorObj.msg, state.data.message.arguments)} iconType="icon_error" messageType="message_error" />)} */}
      <div className="section-form">
        <div className="validate-form">
          <div className={`box`}>
            {/* {errors.msg  && (<FormErrorBox error={errors.msg} iconType="icon_error" messageType="message_error" />)} */}
            {identifierDisplay}
            {identifierTag}
            {forgotUsername}
            {rememberMyUsernameCheckbox}
          </div>
          {buttons}
          {register}

          {state.data && state.data.recaptcha && (
            <>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={state.data.recaptcha['data-sitekey']}
                hl={
                  window.config && window.config.market && window.config.market.locale
                    ? window.config.market.locale.substring(0, 2)
                    : 'en_GB'
                }
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Validate;
