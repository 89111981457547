import { getUrl, isLocalStorage } from '../../utils/utils';
// TODO: Move cookies to config
export const useSMAPI = () => {
  window.getAuthId = function () {
    let authID = 'NA';
    let urlHref = window.location.href.toString();

    if (window.location.hash.includes('state=') || window.location.search.includes('state=')) {
      let urlObj = getUrl();
      authID = decodeURIComponent(urlObj.params.state);
      authID = authID.split(':')[0] ? authID.split(':')[0] : 'NA';
    } else {
      let trxId = urlHref.split('trx/')[1];
      if (trxId !== undefined && trxId.split('/').length > 0) {
        authID = trxId.split('/')[0] ? trxId.split('/')[0] : 'NA';
      }
    }
    return authID;
  };

  const loadSMAPI = () => {
    return new Promise((resolve, reject) => {
      const SMAPILibrary = document.createElement('script');
      SMAPILibrary.src = process.env.PUBLIC_URL + '/sec.min.js?v=8.1.0';
      SMAPILibrary.async = true;
      SMAPILibrary.onload = () => {
        resolve('SMAPI loaded successfully');
      };

      SMAPILibrary.onerror = (error) => {
        if (process.env.REACT_APP_DEBUG_MODE) {
          console.error('failed to load SMAPI');
          console.log(error);
        }

        reject('Failed to load SMAPI');
      };

      document.head.appendChild(SMAPILibrary);
    });
  };

  // SMAPI
  const initiateSmapi = (SMAPIcookiesEnabled) => {
    console.log('');
    console.log('——— SMAPI ————————————');
    console.log('SMAPI loaded');
    console.log('SMAPI version: 8.1.0');
    console.log('');
    const market = window.config ? window.config.market.locale.split('_')[1] : '';
    const service_id = window.service_id ? window.service_id : '';
    const headers = {
      'x-vf-trace-source': 'js:com.vodafone.idpfe.pre',
      'x-vf-trace-source-version':
        'SMAPI Version: 8.1.0 :: SMAPI ENV: ' +
        process.env.REACT_APP_SMAPI_ENV +
        ' :: FE Version: ' +
        window.version +
        ' :: ENV: ' +
        process.env.REACT_APP_ENV,
      'x-vf-custom-authorization-id': window.getAuthId(),
      'x-vf-custom-market': market,
      'x-vf-custom-service-name': service_id ? service_id : 'NA',
    };
    // console.log('usesmapi =', navigator.cookieEnabled, window.config.smapiEnabled);
    if (navigator.cookieEnabled && window.config && window.config.smapiEnabled) {
      try {
        if (process.env.REACT_APP_DEBUG_MODE) {
          console.log('SMAPI enabled');
          console.log('SMAPI cookiesEnabled: ', SMAPIcookiesEnabled);
        }
        window.submitter = window.sec.init({
          enabled: SMAPIcookiesEnabled,
          environment: process.env.REACT_APP_SMAPI_ENV,
          verbose: true,
          cookiesEnabled: true,
          networkMonitoringEnabled: false,
          spaTrackingOptions: {
            enabled: false,
            replaceState: false,
          },
          headers,
        });
        window.submitter.turnOnOff(SMAPIcookiesEnabled);
      } catch (e) {
        if (process.env.REACT_APP_DEBUG_MODE) {
          console.log(e);
        }
      }
    } else {
      if (process.env.REACT_APP_DEBUG_MODE) {
        console.log('SMAPI disabled');
      }
    }
  };

  const handleSMAPI = () => {
    let SMAPIcookieEnabled = true;

    // iOS MVA
    if (isLocalStorage() === true) {
      if (localStorage.getItem('trackStatus') === 'Rejected') {
        SMAPIcookieEnabled = false;
      } else if (localStorage.getItem('trackStatus') === 'Accepted') {
        SMAPIcookieEnabled = true;
      }
    }
    initiateSmapi(SMAPIcookieEnabled);
  };

  const toggleSMAPI = (SMAPIcookieEnabled) => {
    if (SMAPIcookieEnabled && window.config && window.config.smapiEnabled) {
      loadSMAPI().then(() => {
        // Clear session storage to allow reinitiate SMAPI
        sessionStorage.removeItem('smapi_init');
        initiateSmapi(SMAPIcookieEnabled);
      });
    } else {
      initiateSmapi(false);
    }
  };

  const initiateSmapiOnLoad = async (smapiEnabled) => {
    return new Promise((resolve, reject) => {
      try {
        initiateSmapi(smapiEnabled);
        resolve('Smapi Initiated successfully on config load');
      } catch (e) {
        reject('Smapi could not be loaded');
      }
    });
  };

  return {
    loadSMAPI,
    handleSMAPI,
    toggleSMAPI,
    initiateSmapiOnLoad,
  };
};

export default useSMAPI;
