const PAGE_NAME_MAP = {
  AUTHENTICATE: 'login:home',
  AUTHENTICATE_IDENTIFIER: 'AUTHENTICATE_IDENTIFIER',
  AUTHENTICATE_PASSWORD: 'login:home',
  AUTHENTICATE_PIN: 'AUTHENTICATE_PIN',
  AUTHENTICATE_WRONG_CREDENTIALS: 'login:home:wrongcredentials',
  PASSWORD_RESET_IDENTIFIER_MSISDN: 'login:reset password:phone',
  PASSWORD_RESET_IDENTIFIER_PIN: 'PASSWORD_RESET_IDENTIFIER_PIN',
  CONFIRM_OTP_MSISDN: 'login:reset password:verification:phone',
  CONFIRM_OTP_EMAIL: 'login:reset password:verification:email',
  SELF_ENROLLMENT: 'login:registration:user details',
  SELF_ENROLLMENT_SUCCESS: 'SELF_ENROLLMENT_SUCCESS',
  SELF_ENROLLMENT_SUCCESS_STEP_EMAIL: 'SELF_ENROLLMENT_SUCCESS_STEP_EMAIL',
  SELF_ENROLLMENT_SUCCESS_STEP: 'login:registration:success',
  SELF_ENROLLMENT_EMAIL_VERIFIED: 'SELF_ENROLLMENT_EMAIL_VERIFIED',
  ACTIVATE_ACCOUNT_SUCCESS: 'ACTIVATE_ACCOUNT_SUCCESS',
  SELF_ENROLLMENT_EXPIRED: 'SELF_ENROLLMENT_EXPIRED',
  ACTIVATE_ACCOUNT_LINK_EXPIRED: 'ACTIVATE_ACCOUNT_LINK_EXPIRED',
  RESET_PASSWORD_LINK_EXPIRED: 'RESET_PASSWORD_LINK_EXPIRED',
  SELF_ENROLLMENT_MISSING: 'SELF_ENROLLMENT_MISSING',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  FORGOT_USERNAME_EMAIL_PIN: 'login:reset password:email',
  CONFIRM_EMAIL_LINK_STEP: 'CONFIRM_EMAIL_LINK_STEP',
  CONFIRM_EMAIL_END_FLOW_SUCCESS: 'CONFIRM_EMAIL_END_FLOW_SUCCESS',
  CONFIRM_EMAIL_END_FLOW_EXPIRED: 'CONFIRM_EMAIL_END_FLOW_EXPIRED',
  SELF_ENROLLMENT_EMAIL: 'SELF_ENROLLMENT_EMAIL',
  SELF_ENROLLMENT_MSISDN: 'SELF_ENROLLMENT_MSISDN',
  SELF_ENROLLMENT_IDENTIFIER_PASSWORD: 'login:registration:user details',
  SELF_ENROLLMENT_SECOND_FACTOR_MSISDN: '	login:registration:add phone number',
  ACTIVATE_VTV_ADD_IDENTIFIER_MSISDN: 'ACTIVATE_VTV_ADD_IDENTIFIER_MSISDN',
  SOCIAL_ADD_IDENTIFIER_MSISDN: 'SOCIAL_ADD_IDENTIFIER_MSISDN',
  PROGRESSIVE_REGISTRATION_ADD_IDENTIFIER_MSISDN: 'PROGRESSIVE_REGISTRATION_ADD_IDENTIFIER_MSISDN',
  SELF_ENROLLMENT_SECOND_FACTOR_EMAIL: 'login:registration:add email address',
  ACTIVATE_VTV_ADD_IDENTIFIER_EMAIL: 'ACTIVATE_VTV_ADD_IDENTIFIER_EMAIL',
  SOCIAL_ADD_IDENTIFIER_EMAIL: 'SOCIAL_ADD_IDENTIFIER_EMAIL',
  PROGRESSIVE_REGISTRATION_ADD_IDENTIFIER_EMAIL: 'PROGRESSIVE_REGISTRATION_ADD_IDENTIFIER_EMAIL',
  PASSWORD_RESET_IDENTIFIER: 'login:reset password',
  PASSWORD_RESET_EMAIL: 'login:reset password:email',
  SELF_ENROLLMENT_ADD_PIN: 'SELF_ENROLLMENT_ADD_PIN',
  SELF_ENROLLMENT_PIN: 'SELF_ENROLLMENT_PIN',
  FORGOT_USERNAME_PIN: 'FORGOT_USERNAME_PIN',
  PIN_RESET_IDENTIFIER_MSISDN: 'PIN_RESET_IDENTIFIER_MSISDN',
  CONFIRM_MSISDN_PIN: 'CONFIRM_MSISDN_PIN',
  PASSWORD_RESET_OTP_PICKER: 'PASSWORD_RESET_OTP_PICKER',
  PASSWORD_RESET_SELECT_ACCOUNT: 'PASSWORD_RESET_SELECT_ACCOUNT',
  PASSWORD_RESET_SUPPORT: 'login:reset password:password assistance',
  PIN_RESET_SUPPORT: 'PIN_RESET_SUPPORT',
  FORGOT_USERNAME: 'FORGOT_USERNAME',
  PIN_RESET_IDENTIFIER_PASSWORD: 'PIN_RESET_IDENTIFIER_PASSWORD',
  PIN_RESET_ADD_PIN: 'PIN_RESET_ADD_PIN',
  UPDATE_EMAIL_USERNAME: 'UPDATE_EMAIL_USERNAME',
  PASSWORD_RESET_SUCCESS: 'login:reset password:success',
  FORGOT_USERNAME_SUCCESS: 'FORGOT_USERNAME_SUCCESS',
  PIN_RESET_SUCCESS: 'PIN_RESET_SUCCESS',
  ACTIVATE_ACCOUNT_PIN: 'login:registration:verification:second:phone',
  ACTIVATE_ACCOUNT_PASSWORD: 'login:registration:create password',
  SELF_ENROLLMENT_EMAIL_ALREADY_USED: 'SELF_ENROLLMENT_EMAIL_ALREADY_USED',
  MVA_AUTHENTICATE_IDENTIFIER: 'login:home',
  MVA_AUTHENTICATE_PASSWORD: 'login:home',
  MVA_AUTHENTICATE_IDENTIFIER_PASSWORD: 'login:home',
  MVA_SELF_ENROLLMENT_EMAIL_ALREADY_USED: 'MVA_SELF_ENROLLMENT_EMAIL_ALREADY_USED',
  MVA_SELF_ENROLLMENT_MSISDN_ALREADY_USED: 'MVA_SELF_ENROLLMENT_MSISDN_ALREADY_USED',
  MVA_SELF_ENROLLMENT_CONFIRM_OTP_EMAIL: 'login:registration:verification:first:email',
  PASSWORD_RESET_PIN_EMAIL: 'PASSWORD_RESET_PIN_EMAIL',
  MVA_RESET_PASSWORD_OTP_EMAIL: 'login:reset password:verification:email',
  MVA_RESET_PASSWORD_DISAMBIGUATION_OTP_EMAIL: 'login:reset password:verification:email',
  TWO_FACTOR_AUTHENTICATION_CONFIRM_OTP_EMAIL: 'login:2ndFactor:OTP:email',
  VTV_CONFIRM_OTP_EMAIL: 'VTV_CONFIRM_OTP_EMAIL',
  SOCIAL_CONFIRM_OTP_EMAIL: 'SOCIAL_CONFIRM_OTP_EMAIL',
  PROGRESSIVE_REGISTRATION_CONFIRM_OTP_EMAIL: 'PROGRESSIVE_REGISTRATION_CONFIRM_OTP_EMAIL',
  MVA_SELF_ENROLLMENT_CONFIRM_OTP_MSISDN: 'login:registration:verification:first:phone',
  MVA_RESET_PASSWORD_OTP_MSISDN: 'login:reset password:verification:phone',
  PASSWORD_RESET_PIN: 'PASSWORD_RESET_PIN',
  MVA_RESET_PASSWORD_DISAMBIGUATION_OTP_MSISDN: 'login:reset password:verification:phone',
  TWO_FACTOR_AUTHENTICATION_CONFIRM_OTP_MSISDN: 'login:2ndFactor:OTP:phone',
  VTV_CONFIRM_OTP_MSISDN: 'VTV_CONFIRM_OTP_MSISDN',
  SOCIAL_CONFIRM_OTP_MSISDN: 'SOCIAL_CONFIRM_OTP_MSISDN',
  PROGRESSIVE_REGISTRATION_CONFIRM_OTP_MSISDN: 'PROGRESSIVE_REGISTRATION_CONFIRM_OTP_MSISDN',
  MVA_PASSWORD_RESET_NEW_PASSWORD: 'MVA_PASSWORD_RESET_NEW_PASSWORD',
  PASSWORD_RESET_NEW_PASSWORD: 'PASSWORD_RESET_NEW_PASSWORD',
  MVA_RESET_PASSWORD_SECOND_FACTOR_EMAIL: 'MVA_RESET_PASSWORD_SECOND_FACTOR_EMAIL',
  MVA_RESET_PASSWORD_SECOND_FACTOR_MSISDN: 'MVA_RESET_PASSWORD_SECOND_FACTOR_MSISDN',
  MVA_RESET_PASSWORD_MISSING_FACTOR: 'login:reset password:password assistance',
  SELF_ENROLLMENT_IDENTIFIER_PASSWORD_EMAIL: 'login:registration:user details',
  SELF_ENROLLMENT_IDENTIFIER_PASSWORD_MSISDN: 'login:registration:user details',
  REGISTRATION_EMAIL_ALREADY_USED: 'REGISTRATION_EMAIL_ALREADY_USED',
  REGISTRATION_MSISDN_ALREADY_USED: 'REGISTRATION_MSISDN_ALREADY_USED',
  PASSWORD_RESET_TWO_FACTORS_EMAIL: 'login:reset password',
  PASSWORD_RESET_TWO_FACTORS_IDENTIFIER: 'PASSWORD_RESET_TWO_FACTORS_IDENTIFIER',
  MVA_RESET_PASSWORD_TWO_FACTORS_FIRST_FACTOR_OTP: 'login:reset password:verification:email',
  MVA_RESET_PASSWORD_TWO_FACTORS_SECOND_FACTOR_OTP: 'login:reset password:verification:phone',
  MVA_PASSWORD_RESET_TWO_FACTORS_NEW_PASSWORD: 'login:reset password:new password',
  TWO_FACTORS_PASSWORD_RESET_SUCCESS: 'login:reset password:success',
  MVA_RESET_PASSWORD_UNVERIFIED_SECOND_FACTOR: 'login:reset password:unverified second factor',
  MVA_RESET_PASSWORD_MISSING_ACCOUNT: 'login:reset password:missing account',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  END_FLOW: 'redirect',
  END_FLOW_LOADING: 'redirect:retry',
  FOLLOW: 'redirect',
};

/**
 * Returns page name used in the analytics reports according to the internal page ids
 * @param {string} pageId - ID of the page (context name)
 * @returns {string} page name from the pre-configured map
 */
function getPageName(pageId) {
  if (!PAGE_NAME_MAP.hasOwnProperty(pageId)) {
    console.error(`Page name for ID "${pageId}" is not found`);
    return pageId;
    //throw new Error(`Analytics: page name for ID "${pageId}" is not found`);
  }

  return PAGE_NAME_MAP[pageId];
}

export { getPageName };
