import VFLogger from './Logger';
/* eslint-disable no-mixed-spaces-and-tabs */
export function parseQSParams(str) {
  let l = 0;
  let res = {};
  let arrQS = str.split('&');
  for (let i = 0; i < arrQS.length; i++) {
    let t = arrQS[i].split('=');
    res[t[0]] = t[1];
    l++;
  }
  res.length = l;
  return res;
}

export function getUrl() {
  let qs = window.location.search;
  let cr = window.location.hash;

  let obj = {};

  if (qs !== '') {
    qs = qs.substring(1);
    obj.controller = 'RedirectURLWithError';
    obj.params = parseQSParams(qs);
  } else if (cr !== '' && typeof cr !== 'undefined') {
    cr = cr.substring(1);
    obj = parseHash(cr);
  } else {
    obj.controller = '';
  }

  return obj;
}

export function isLocalStorage() {
  let test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export const uuid = () => {
  let d = new Date().getTime();
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now();
  }
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

/**
 * Generates a version 4 UUID using browsers crypto API, has appropriate level of support,
 * but some very old browsers are not supported (https://caniuse.com/?search=getRandomValues)
 * @returns {string} A version 4 UUID.
 */
function uuidV4() {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
}

/**
 * Generates a UUID using the version 4 algorithm.
 * @returns {string|undefined} A version 4 UUID or undefined if generation fails.
 */
export const generateUUID = () => {
  try {
    return uuidV4();
  } catch (error) {
    console.warn('Unable to generate UUID v4');
    console.error(error);
    return undefined;
  }
};

/* Get the top Domain Name from the url*/
export const getTopDomainName = () => {
  let url = window.location.href;
  if (url.indexOf('localhost') < 0) {
    if (typeof url === 'string') url = new URL(url);
    const domain = url.hostname;
    const elems = domain.split('.');
    const iMax = elems.length - 1;

    const elem1 = elems[iMax - 1];
    const elem2 = elems[iMax];

    const isSecondLevelDomain = iMax >= 3 && (elem1 + elem2).length <= 5;
    return (isSecondLevelDomain ? elems[iMax - 2] + '.' : '') + elem1 + '.' + elem2;
  } else {
    return 'localhost';
  }
};

export function logFocusedField(context, field) {
  VFLogger.info(`${context}: ${field} field focused`);
}

export const replaceIFrameSrc = (htmlString) => {
  const market = window.config ? window.config.market.countryCode.toLowerCase() : '';
  // Define the string to prepend
  let url = process.env.REACT_APP_BACKEND_ENDPOINT;
  url = url.replace(/\/$/, ''); // removing last slash
  // to append opco in backend endpoint
  let prependString = url.replace(/^https:\/\//, `https://${market}.`);
  // Use a regular expression to match and modify the src attribute
  htmlString = htmlString.replace(/<iframe\s+[^>]*src="([^"]+)"[^>]*>/g, (match, capturedSrc) => {
    // Prepend the new string to the captured src
    const newSrc = prependString + capturedSrc + '&X-SPA=1';
    // Replace the original src with the new one in the iframe tag
    return match.replace(capturedSrc, newSrc);
  });

  htmlString = htmlString.replace(/<script\s+[^>]*src=["']([^"']+)["']/g, (match, src) => {
    // Prepend the string to the captured src
    return match.replace(src, prependString + src);
  });
  return htmlString;
};
