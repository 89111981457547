import Validate from '../Validate/Validate';
import Authenticate from '../Authenticate/Authenticate';
import WelcomeBack from '../WelcomeBack/WelcomeBack';
import ConfirmPin from '../ConfirmPin/ConfirmPin';
import NonVodafoneEnabled from '../NonVodafoneEnabled/NonVodafoneEnabled';
import Registration from '../Registration/Registration';
import EmailVerification from '../EmailVerification/EmailVerification';
import EndFlow from '../EndFlow/EndFlow';
import UpdateProfile from '../UpdateProfile/UpdateProfile';
import EndFlowLoading from '../EndFlowLoading/EndFlowLoading';
import AccountSelection from '../AccountSelection/AccountSelection';
import SelfcareKYC from '../SelfcareKYC/SelfcareKYC';
import { getPageName } from '../../constants/tealiumMapper';
import analyticsTealium from '../../utils/Analytics/analytics';

export default function getContextRender(context, ServiceRequest, RequestCallBack, state) {
  const { trackPageView } = analyticsTealium();
  let contextRender = '';
  switch (context) {
    case 'VALIDATE':
    case 'VALIDATE_GB_USERNAME':
    case 'VALIDATE_GB_REGISTER':
    case 'VALIDATE_FORGOT_USERNAME':
    case 'MSISDN_INPUT':
    case 'VALIDATE_ES_USERNAME':
      contextRender = <Validate RequestCallBack={RequestCallBack}></Validate>;
      break;
    case 'AUTHENTICATE':
      contextRender = <Authenticate RequestCallBack={RequestCallBack}></Authenticate>;
      break;
    case 'WELCOME_BACK':
      contextRender = <WelcomeBack RequestCallBack={RequestCallBack}></WelcomeBack>;
      break;
    case 'CONFIRM_PIN':
      contextRender = <ConfirmPin RequestCallBack={RequestCallBack}></ConfirmPin>;
      break;
    case 'NON_VODAFONE_ENABLED':
      contextRender = <NonVodafoneEnabled RequestCallBack={RequestCallBack}></NonVodafoneEnabled>;
      break;
    case 'REGISTRATION':
    case 'CONFIRM_RESET_PASSWORD':
    case 'CONFIRM_PIN_PASSWORD':
      contextRender = <Registration RequestCallBack={RequestCallBack}></Registration>;
      break;
    case 'UPDATE_PROFILE':
      contextRender = <UpdateProfile RequestCallBack={RequestCallBack}></UpdateProfile>;
      break;
    case 'ACCOUNT_SELECTION':
      contextRender = <AccountSelection RequestCallBack={RequestCallBack}></AccountSelection>;
      break;
    case 'SELFCARE_KYC':
      contextRender = <SelfcareKYC RequestCallBack={RequestCallBack}></SelfcareKYC>;
      break;
    case 'ES_EMAIL_VERIFICATION':
      contextRender = <EmailVerification RequestCallBack={RequestCallBack} />;
      break;
    case 'END_FLOW':
    case 'END_FLOW_SUCCESS':
      contextRender = <EndFlow RequestCallBack={RequestCallBack}></EndFlow>;
      break;
    case 'END_FLOW_LOADING':
      contextRender = <EndFlowLoading RequestCallBack={RequestCallBack}></EndFlowLoading>;
      break;
    case 'FOLLOW':
      trackPageView(getPageName(state.data.context), { flowName: 'login', flowType: 'login' });
      ServiceRequest(state.data.links['urn:vodafoneid:follow'], null, null, 'self');
      break;
  }
  return contextRender;
}
