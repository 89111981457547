import React, { useContext } from 'react';
import './error.scss';
import { StoreContext } from '../../store/store';
const Error = (props) => {
  const { state } = useContext(StoreContext);
  const title = props.title ? props.title : 'Something went wrong';
  return (
    <>
      <div className={`vf-error-wrapper ${state.page_look}`}>
        <div className="vf-error">
          <div className="vf-error-icon-wrapper">
            <div className="vf-error-icon"></div>
          </div>
          <div className="vf-error-title">{title}</div>
          <div className="vf-error-text">{props.text}</div>
        </div>
      </div>
    </>
  );
};

export default Error;
