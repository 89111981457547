import React, { useState, useEffect, useContext, useRef } from 'react';
import { StoreContext } from '../../store/store';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Link from '../Link/Link';
import FormErrorBox from '../FormErrorBox/FormErrorBox';
import { text, encryptPassword } from '../../utils/global';
import './WelcomeBack.scss';
import { ServiceRequest, handleLink } from '../../utils/Services';
import useForm from './useForm';
import ReCAPTCHA from 'react-google-recaptcha';
import analyticsTealium from '../../utils/Analytics/analytics';
import VFLogger from '../../utils/Logger';
import { getSmapiPageName } from '../../constants/smapiMapper';

const WelcomeBack = (props) => {
  const recaptchaRef = useRef();
  let { state } = useContext(StoreContext);
  let buttons = [];
  let identifier =
    state.data.identifier.type === 'tel' ? '+' + state.data.identifier.value : state.data.identifier.value;
  let passwordTag,
    forgotPassword = false;
  const [passwordType, setPasswordType] = useState('password');
  const [passwordIcon, setPasswordIcon] = useState('show');
  const { trackPageEvent } = analyticsTealium();
  const {
    errors,
    values,
    titleClassName,
    paddingClassName,
    buttonType,
    setButtonType,
    handleSubmit,
    handleChange,
    resetValues,
    handleBlur,
  } = useForm((e) => onSubmitWithReCAPTCHA(e));

  //DOM load event
  useEffect(() => {
    //On Error Clear the screen
    if (state.errorObj && state.errorObj.msg) {
      resetValues();
    }
    if (state.data.links['urn:vodafoneid:submitIdentifier']) {
      setButtonType('primary');
    }
    // reset reCAPTCHA
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  }, [state.errorObj]);

  const onSubmitWithReCAPTCHA = async () => {
    console.log('--- onSubmitWithReCAPTCHA ---');

    if (recaptchaRef.current) {
      // Submit with reCAPTCHA
      try {
        console.log('reCAPTCHA: get token');
        const token = await recaptchaRef.current.executeAsync();
        console.log('token: ' + token);
        // reset reCAPTCHA
        recaptchaRef.current.reset();
        if (!token) {
          console.log('reCAPTCHA: get token failed');
        }
        submitForm(token);
      } catch (error) {
        console.log('ERROR: ' + error);
      }
    } else {
      // Normal submit
      submitForm();
    }
  };

  //Buttons / links
  if (state.data.links !== null || state.data.links !== undefined) {
    //Submit button (Continue)
    if (state.data.links['urn:vodafoneid:submitIdentifier'] || state.data.links['urn:vodafoneid:authenticate']) {
      buttons.push(
        <div className="btn-wrapper" key="btn1">
          <Button
            type={buttonType}
            label={text('submit.button.label')}
            id="continueButton"
            handleClick={(event) => {
              trackPageEvent(state.data.context, { eventCategory: 'button', eventLabel: text('submit.button.label') }),
                handleSubmit(event);
            }}
          />
        </div>
      );
    }

    //Password input tag
    if (state.data.links['urn:vodafoneid:authenticate']) {
      passwordTag = (
        <Input
          type={passwordType}
          className={errors.msgClass}
          label={text('label.for.existing.password')}
          titleClassName={titleClassName}
          paddingClassName={paddingClassName}
          name="password"
          id="password"
          minLength={10}
          placeholder={text('placeholder.for.existing.password')}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleKey={handleKeyPress}
          value={values.password}
          showIcon={true}
          iconClick={handleIconClick}
          iconClass={passwordIcon}
          errMsg={errors.msg}
        ></Input>
      );
    } else {
      //setButtonType('primary');
    }

    //Forgot Passoword link
    if (state.data.links['urn:vodafoneid:resetpassword']) {
      forgotPassword = (
        <Link
          id="forgotPassword"
          handleLink={() => {
            trackPageEvent(state.data.context, {
              eventCategory: 'link',
              eventLabel: text('forgot.password.link.text'),
            }),
              handleLink(
                state.data.links['urn:vodafoneid:resetpassword'],
                props.RequestCallBack,
                '',
                null,
                'Click on - Reset Password',
                'Clicked on Reset Password'
              );
          }}
          label={text('forgot.password.link.text')}
        ></Link>
      );
    }
  }

  //on Key Press Handler for Key Press on MSISDN input field
  function handleKeyPress(event) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 13 && charCode !== 32) {
      return true;
    } else if (charCode == 13) {
      handleSubmit(event);
    }
  }

  function handleIconClick(event) {
    if (event.target.id) {
      setPasswordIcon(event.target.className.indexOf('show') !== -1 ? 'hide' : 'show');
      setPasswordType(event.target.className.indexOf('show') !== -1 ? 'text' : 'password');
    }
  }

  function submitForm(token) {
    VFLogger.info(`${getSmapiPageName(state.data.context)}: form submit`);
    console.log('Click on Submit');
    setButtonType('primary spinner disabled');
    let submitLink = state.data.links['urn:vodafoneid:submitIdentifier'];

    let jsonObj = {
      identifier:
        state.data.identifier.type === 'tel' ? '+' + state.data.identifier.value : state.data.identifier.value,
      identifierType: state.data.identifier.type,
      remember_my_username: true,
    };

    if (values.password !== '' && state.data.links['urn:vodafoneid:authenticate'] !== undefined) {
      submitLink = state.data.links['urn:vodafoneid:authenticate'];
      const encrypedPwd = encryptPassword(state.data.links['urn:vodafoneid:authenticate'], values.password);
      jsonObj['password'] = encrypedPwd;
    }
    // Add reCAPTCHA token
    if (token !== undefined) {
      jsonObj['recaptcha_token'] = token;
    }
    const payload = JSON.stringify(jsonObj);

    ServiceRequest(submitLink, payload, props.RequestCallBack);
  }

  return (
    <>
      {state.errorObj && state.errorObj.msg && (
        <FormErrorBox
          messageClass="form-error"
          error={text(state.errorObj.msg)}
          iconType="icon_error"
          messageType="message_error"
        />
      )}
      <div className="section-form">
        <div className="box">
          <div className="user-name">
            <div className="section-form-label">{text('label.for.identifier.welcome_back')}</div>
            <div className="section-form-label notyoulink">
              {identifier} &nbsp;
              <a
                id="notyoulink"
                onClick={() => {
                  trackPageEvent(state.data.context, {
                    eventCategory: 'link',
                    eventLabel: text('not.received.pin.edit.username.label'),
                  }),
                    handleLink(
                      state.data.links['urn:vodafoneid:changeidentifier'],
                      props.RequestCallBack,
                      '',
                      null,
                      'Click on - Change Identifier',
                      'Clicked on Change Identifier'
                    ),
                    VFLogger.info(`${state.data.context}: Edit username click`);
                }}
              >
                {text('not.received.pin.edit.username.label')}
              </a>
            </div>
          </div>
          {passwordTag}
          {forgotPassword}
        </div>
        {buttons}
        {state.data && state.data.recaptcha && (
          <>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={state.data.recaptcha['data-sitekey']}
              hl={
                window.config && window.config.market && window.config.market.locale
                  ? window.config.market.locale.substring(0, 2)
                  : 'en_GB'
              }
            />
          </>
        )}
      </div>
    </>
  );
};

export default WelcomeBack;
